<template>
	<div>
		<div v-if="shopInfo">
			<div style="width: 100px;height: 100px;margin: 0 auto;padding-top: 20px;">
				<img :src="shopInfo.avatar" style="width: 100%;height: 100%;border-radius: 50%;" />
			</div>
			<div style="text-align: center;line-height: 40px;">{{shopInfo.nickname}}</div>

			<div style="text-align: center;line-height: 40px; font-weight:bold;">
				注册码：{{shopInfo.shop_code}}
				<a style="margin-left: 40px;color: royalblue;" href="/jiaocheng">使用教程</a>
				<a style="margin-left: 40px;color: royalblue;" href="/h5Shop">店铺设置</a>
			</div>

			<div style="text-align: center;padding: 20px 0;border: 1px solid #eee;border-left: 0;border-right: 0;">
				<span>总收入:{{shopInfo.income}}元</span>
				<span style="margin-left: 20px;">已提现:{{shopInfo.tixian}}元</span>

				
			</div>
			<div style="text-align: center;font-size: 12px;color: orangered;" >每天中午12点自动提现，请注意查收红包</div>

			<div style="text-align: center;padding: 20px 0;">
				<div style="width: 120px;height:120px ;display: inline-block;vertical-align: top;margin-right: 20px;">
					<img id="qrcode_print" :src="shopInfo.qrcode_print"
						style="width: 100%;height: 100%;border-radius: 50%;" @click="showQrcodeView('qrcode_print')" />
					<div style="font-size: 12px;text-align: center;">
						打印码（扫码打印）
					</div>
				</div>
				<div style="width: 120px;height:120px ;display: inline-block;vertical-align: top;">
					<img id="qrcode_family" :src="shopInfo.qrcode_family"
						style="width: 100%;height: 100%;border-radius: 50%;" @click="showQrcodeView('qrcode_family')" />
					<div style="font-size: 12px;text-align: center;">
						家庭码（免费打印）
					</div>
				</div>


			</div>

			<div style="text-align: center;padding-top: 10px; margin-bottom:500px">
				点击查看大图
			</div>

			<div style="text-align: center;padding: 20px 0;">
				<div id="qrcode" ref="qrcode"
					style="width: 120px;height:120px ;display: inline-block;vertical-align: top;margin-right: 20px;"
					@click="showQrcodeView('qrcode_yq')">


				</div>
				<div style="font-size: 12px;text-align: center;">
					邀请码2（邀请店主使用您会获得持续收入）
				</div>



			</div>



			<div style="text-align: center;padding: 50px;color: orangered;">
				佣金收入说明：邀请人会获得被邀请人所有订单总额的2%作为佣金，所有金额实时结算，超过10元即可提现！
			</div>

		</div>

		<div id="viewurl" @click="closeView"
			style=" display:none; position: fixed;background-color: rgba(0, 0, 0, 0.5);width: 100%;height: 100%;top: 0; z-index: 10;text-align: center;">

			<div style="height: 15%;"></div>
			<img :src="viewurl" style="width: 80%;" />

			<div
				style="text-align: center;background-color: #fff;line-height: 40px;width: 80%;margin: 0 auto;color: orangered;font-weight: bolder;">
				长按二维码保存至相册</div>

		</div>

		<div style="width: 1px;height: 1px; overflow: hidden;">
			<canvas id="imgviewcvs"
				style="width: 794px;height: 1123px;transform: scale(0.4,0.4);transform-origin: left top;" width="794px"
				height="1123px"></canvas>
		</div>


	</div>
</template>

<script>
	import QRCode from 'qrcodejs2'
	export default {
		data() {
			return {
				shopInfo: null,
				qrcodeUrl: "",
				viewurl: ""
			}
		},
		mounted() {
			this.waitLogin().then(_ => {
				this.getData()

			})

		},
		methods: {
			creatQrCode(url) {
				var qrcode = new QRCode(this.$refs.qrcode, {
					text: url, // 需要转换为二维码的内容
					width: 430,
					height: 430,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
				setTimeout(() => {
					$("img", "#qrcode").css("height", "100%").css("width", "100%")
					
				}, 1000)
			},
			getData() {
				this.$http.post("/api/get_shop_info").then(res => {

					if (!res.data.qrcode_print || !res.data.qrcode_family) {
						this.createPrintQrcode()

					} else {
						res.data.qrcode_print = res.data.qrcode_print.replace("https:", window.location.protocol)
							.replace("bilinweiyin.oss-cn-hangzhou.aliyuncs.com", "blwy.mmteck.cn")
						res.data.qrcode_family = res.data.qrcode_family.replace("https:", window.location.protocol)
							.replace("bilinweiyin.oss-cn-hangzhou.aliyuncs.com", "blwy.mmteck.cn")

					}
					this.shopInfo = res.data
					if (!res.data.qrcode_yq) {
						this.getQrcode()
					} else {
						this.$nextTick(() => {
							this.creatQrCode(res.data.qrcode_yq)

						})
					}


				})
			},

			createPrintQrcode() {

				this.$http.post("/api/create_shop_qrcode").then(res => {
					this.createFamilyQrcode()

				})


			},
			createFamilyQrcode() {

				this.$http.post("/api/create_family_qrcode").then(res => {
					this.getData()

				})


			},
			getQrcode() {
				this.$http.post("/api/create_yq_qrcode", {}).then(res => {



					this.getData()

				})



			},
			showQrcodeView(type) {
				console.log(type)
				let cvs = document.getElementById("imgviewcvs").getContext("2d")
				let img = new Image()
				if (type != "qrcode_yq") {
					img.src = this.shopInfo[type]
					img.onload = e => {
						cvs.fillStyle = "#FFFFFF";
						cvs.fillRect(0, 0, 794, 1123);
						cvs.drawImage(img, 0, 0, img.width, img.height, 182, 182, img.width, img.height)
						cvs.fillStyle = "#000000";

						if (type == "qrcode_print") {
							cvs.font = "60px Arial";
							cvs.fillText("扫码打印", 270, 750);
						} else {
							cvs.font = "50px Arial";
							cvs.fillText("扫码打印FREE", 240, 750);
						}


						let base64 = document.getElementById("imgviewcvs").toDataURL('image/jpeg')
						this.viewurl = base64
						$("#viewurl").show()

					}
				} else {
					
					
					let imgurl = $("canvas", "#qrcode")[0].toDataURL('image/jpeg')
					let img = new Image();
					img.src = imgurl;
					img.onload = e => {
						cvs.fillStyle = "#FFFFFF";
						cvs.fillRect(0, 0, 794, 1123);
						cvs.drawImage(img, 0, 0, 430, 430, 182, 182, 430, 430)
						cvs.fillStyle = "#000000";
						cvs.font = "50px Arial";
						cvs.fillText("注册比邻微印", 240, 750);
						let base64 = document.getElementById("imgviewcvs").toDataURL('image/jpeg')
						this.viewurl = base64
						$("#viewurl").show()
					}
				}





			},
			closeView() {
				$("#viewurl").hide()
			}


		}
	}
</script>

<style>
	#qrcode img {
		width: 100% !important;
		height: 100% !important;
	}
	#qrcode canvas {
		width: 100% !important;
		height: 100% !important;
	}
</style>
